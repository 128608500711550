.pawoo-oauth-registration__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    >img {
        width: 120px;
        height: 120px;
        display: block;
        border-radius: 120px;
        box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);
        margin-top: 40px;
    }

    >.omniauth {
        margin: 0 20px;
        width: 400px;
    }
}

.pawoo-oauth-registration__actions {
    margin: auto;
    max-width: 540px;
}

.oauth-authentications-new {
    .signout {
        margin-top: 50px;
        display: block;
        color: #9baec8;
        text-transform: uppercase;
        text-align: center;
    }
}

.simple_form {
    .omniauth {
        .label {
            display: inline-block;
            margin-top: 10px;
        }

        .label,
        label {
            opacity: 0.7;
        }
    }

    button,
    .button,
    .block-button {
        &.omniauth-pixiv {
            text-transform: none;
        }
    }
}