.time-limit-dropdown {
    position: absolute;
    right: 2px;
    top: 30px;
}

.time-limit-dropdown__dropdown {
    position: absolute;
    background: $simple-background-color;
    box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
    border-radius: 4px;
    margin-left: 0;
    overflow: hidden;
}

.time-limit-dropdown__header {
    padding: 10px;
    color: $ui-base-color;
    border-bottom: 1px solid lighten($ui-secondary-color, 4%);
}

.time-limit-dropdown__header_note {
    font-size: 10px;
    color: lighten($ui-base-color, 50%);
}

.time-limit-dropdown__option {
    color: $inverted-text-color;
    padding: 10px;
    cursor: pointer;
    display: flex;

    &:hover,
    &.active {
        background: $ui-highlight-color;
        color: $primary-text-color;
        outline: 0;

        .time-limit-dropdown__option__content,
        .privacy-dropdown__option__content {
            color: $primary-text-color;

            strong {
                color: $primary-text-color;
            }
        }
    }

    &.active:hover {
        background: lighten($ui-highlight-color, 4%);
    }
}

.time-limit-dropdown__option {
    color: $inverted-text-color;
    padding: 10px;
    cursor: pointer;
    display: flex;

    &:hover,
    &.active {
        background: $ui-highlight-color;
        color: $primary-text-color;
        outline: 0;

        .time-limit-dropdown__option__content,
        .privacy-dropdown__option__content {
            color: $primary-text-color;

            strong {
                color: $primary-text-color;
            }
        }
    }

    &.active:hover {
        background: lighten($ui-highlight-color, 4%);
    }
}

.time-limit-dropdown__option__content {
    flex: 1 1 auto;
    color: $lighter-text-color;

    strong {
        font-weight: 500;
        display: block;
        color: $inverted-text-color;

        @each $lang in $cjk-langs {
            &:lang(#{$lang}) {
                font-weight: 700;
            }
        }
    }
}